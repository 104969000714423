import {debounceTime, distinctUntilChanged, filter, startWith, take} from 'rxjs/operators';
import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import {Subject, takeUntil} from 'rxjs';
import {Intercom} from 'ng-intercom';

import {PrimeNGConfig} from 'primeng/api';

import {APP_VERSION, defaultPopupConfig, mainConstants} from '@wallex-core-client/core/constants/main-constants';
import {CustomRouterService} from '@wallex-core/services/custom-router.service';
import {AppStateFacade} from '@wallex-core/store/app/app-state-facade.service';
import {Locale} from '@wallex-core/constants/language.constants';
import {AuthService} from '@wallex-core/services/auth.service';
import {DefaultDialogComponent} from '@wallex-core-client/ui';
import {AppService} from '@wallex-core/services/app.service';
import {environment} from '~/environments/environment';
import {saveItem} from '@wallex-core-client/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public blockedDocument: boolean = false;
  private isErrorDialogShown: boolean = false;
  private notifier$ = new Subject<void>();

  constructor(
    public appStateFacade: AppStateFacade,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private authService: AuthService,
    private customRouterService: CustomRouterService,
    private dialogService: DialogService,
    private intercom: Intercom,
    private appService: AppService,
    private primeNGConfig: PrimeNGConfig
  ) {}

  ngOnInit() {
    saveItem(mainConstants.VERSION_KEY, APP_VERSION);
    this.initializeValues();
  }

  private initializeValues(): void {
    this.translate.onLangChange.pipe(startWith({lang: this.translate.currentLang}), takeUntil(this.notifier$)).subscribe(({lang}) => {
      if (lang === Locale.RU || lang === Locale.BG) {
        document.body.classList.add('cyrillic');
        return;
      }

      document.body.classList.remove('cyrillic');
    });

    this.translate
      .get('primeng')
      .pipe(takeUntil(this.notifier$))
      .subscribe(translations => {
        this.primeNGConfig.setTranslation(translations);
      });

    this.appStateFacade.showSpinner$.pipe(distinctUntilChanged(), debounceTime(100), takeUntil(this.notifier$)).subscribe(showSpinner => {
      this.blockedDocument = showSpinner;
      this.cdr.detectChanges();
    });

    this.appStateFacade.serverErrorMessage$.pipe(takeUntil(this.notifier$)).subscribe(error => {
      if (error?.message.length && error?.title.length && !this.isErrorDialogShown) {
        this.handleError(error.title, error.message);
      }
    });

    this.authService.refreshTokenIfNeeded$.pipe(takeUntil(this.notifier$)).subscribe();

    this.customRouterService.pageTitle$.pipe(takeUntil(this.notifier$)).subscribe();

    this.customRouterService.isMainScreenActive$.pipe(takeUntil(this.notifier$)).subscribe();

    this.authService.logouted$.pipe(takeUntil(this.notifier$)).subscribe(() => {
      this.initializeIntercom();
    });

    this.appStateFacade.config$.pipe(take(1), takeUntil(this.notifier$)).subscribe(config => {
      if (!config.isMocked) return;

      this.getAppConfig();
    });

    this.initializeIntercom();
  }

  private getAppConfig(): void {
    this.appService.getAppConfiguration().subscribe(
      config => {
        this.appStateFacade.config$.next(config);
      },
      () => {
        this.serverUnavailableDialog();
      }
    );
  }

  private initializeIntercom(): void {
    const intercomColor = getComputedStyle(document.body).getPropertyValue('--intercom-color');

    this.intercom.boot({
      app_id: environment.intercomId,
      background_color: intercomColor,
      action_color: intercomColor,
      widget: {
        activator: '#intercom'
      }
    });
  }

  private serverUnavailableDialog(): void {
    const ref = this.dialogService.open(DefaultDialogComponent, {
      ...defaultPopupConfig(this.translate.instant('errors.app_config.title')),
      data: {
        message: this.translate.instant('errors.app_config.text'),
        okayButtonLabel: this.translate.instant('common.retry'),
        isShowCloseButton: false
      }
    });

    ref.onClose.subscribe(() => {
      this.getAppConfig();
    });
  }

  private handleError(title: string, message: string): void {
    this.isErrorDialogShown = true;

    const ref = this.dialogService.open(DefaultDialogComponent, {
      ...defaultPopupConfig(title),
      styleClass: 'no-x-button error-dialog',
      data: {message}
    });

    ref.onClose.subscribe(() => {
      this.isErrorDialogShown = false;
    });
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
