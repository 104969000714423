import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {LOCALE_COOKIE_KEY} from '@wallex-core-client/core/constants/main-constants';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clone = req.clone({
      setHeaders: {
        'x-lang': this.cookieService.get(LOCALE_COOKIE_KEY)
      }
    });

    return next.handle(clone);
  }
}
